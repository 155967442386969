import {
  Avatar,
  Box,
  // Breadcrumb,
  // BreadcrumbItem,
  BreadcrumbLink as BCL,
  BreadcrumbLinkProps as BCLProps,
  Button,
  FormControl,
  HStack,
  Input,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
  Tabs,
  TabList,
  TabPanel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  useCancelWithdrawalRequestMutation,
  useCheckReceiverIsInternalQuery,
  useGetMinimumWithdrawalAmountQuery,
  useGetWithdrawalsQuery,
  useResolveCryptoDomainQuery,
  useWithdrawalFeeEstimateMutation,
} from "apis";
import {
  AmountInput,
  Card,
  ConditionalRender,
  DetailItem,
  EmptyCrate,
  FormInfo,
  Group,
  InputLabel,
  PageLoading,
  Paginator,
  Select,
  Steps,
  Tab,
  TabPanels,
  Title,
  TitleBar,
  TransactionItem,
  useModalView,
  useModalView as useSteps,
} from "components";
import { useSidePage } from "contexts";
import { format, parseISO } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { useColor, useDisclosures, usePartialState, useRedebounce, useWalletBalance } from "hooks";
import { WalletWithdrawalRo } from "interfaces";
import { useEffect, useMemo, useState } from "react";
import { ConfirmationModal } from "ui";
import CopyCryptoAddressWarningModal from "ui/Wallet/Modals/CopyCryptoAddressWarning";
import CryptoWithdrawPreview from "ui/Wallet/Modals/CryptoWithdrawPreview";
import { toCoinLocale, toPrecision, toQueryString, when } from "utils";
import { properCase } from "utils/textFormatter";

interface WithdrawProps {}

export interface IState {
  destinationFormat: "address" | "cryptoDomain" | "username" | "email";
  network: "tron" | "ethereum" | "polygon" | "stellar" | "algorand" | "ton";
  amount: string;
  address: string;
  memo: string;
  tag: string;
  pin: string;
  note: string;
  withdrawal_confirmation_message?: string;
  withdrawal_confirmation_fee?: number;
}

export default function Withdraw(props: WithdrawProps) {
  // const { setCurrentView } = useSteps();

  // const { params } = useSidePage();
  // const coin = useMemo(() => params?.coin ?? "btc", [params]);

  const { updateSidePageConfigs } = useSidePage();
  const { onPrev, hasPrev, currentView } = useModalView();

  console.log("Has prev", { hasPrev, currentView });

  const setBackButtonConfig = () => {
    if (!!hasPrev) {
      updateSidePageConfigs({
        _back: {
          text: `Overview`,
          onClick: onPrev,
        },
      });
    }
  };

  return (
    <Box id="crypto-withdraw" px={{ base: "20px", md: "40px" }} {...props} overflowY="scroll">
      <Steps hideIndicator>
        <WithdrawalIndexView setBackButtonConfig={setBackButtonConfig} {...props} />
        <WithdrawalDetailsView {...props} />
      </Steps>
    </Box>
  );
}

function Form(props: WithdrawProps & { coin: string }) {
  const { coin } = props;
  const { setCurrentView } = useSteps();

  const { getBalance } = useWalletBalance();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const balance = useMemo(() => getBalance(coin as any) ?? 0, [coin, getBalance]);

  const [state, _set, reset] = usePartialState<IState>({
    destinationFormat: "address",
    network: when(["usdc", "usdt"].includes(coin), coin === "usdc" ? "ethereum" : "tron", undefined),
  });

  const [inputState, setInputState] = usePartialState({
    address: "idle" as "typing" | "idle",
  });

  const redebounce = useRedebounce();

  const isInternalAddressLabel = useMemo(
    () => ["username", "email"].includes(String(state.destinationFormat)),
    [state.destinationFormat]
  );
  const isAddressLabel = useMemo(
    () => isInternalAddressLabel || ["cryptoDomain"].includes(String(state.destinationFormat)),
    [isInternalAddressLabel, state.destinationFormat]
  );

  const skipAddressCheck = useMemo(() => {
    if (inputState.address !== "idle") return true;
    if (!state?.address) return true;
    if (isInternalAddressLabel) return true;
    if (coin && ["xlm", "ton"].includes(coin) && !state?.memo && !isAddressLabel && state?.address) return true;
    if (coin && ["xrp"].includes(coin) && !state?.tag && isAddressLabel && state?.address) return true;
    return false;
  }, [coin, state, inputState.address, isInternalAddressLabel, isAddressLabel]);

  const query = useMemo(() => {
    if (["usdt", "usdc"].includes(coin) && state?.network) {
      if (!!state?.note) return `?network=${state?.network}&note=${state?.note}`;
      else return `?network=${state?.network}${state?.network === "ton" && state?.memo ? `&memo=${state.memo}` : ""}`;
    }
    if (["xlm", "ton"].includes(coin) && state?.memo) return `?memo=${state?.memo}`;
    if (["xrp"].includes(coin) && state?.tag) return `?tagNumber=${state?.tag}`;
    return "";
  }, [coin, state]);

  const { data: checkAddressData, isFetching } = useCheckReceiverIsInternalQuery(
    { address: state?.address!, query, coin },
    { skip: state?.destinationFormat !== "address" || !state?.address || skipAddressCheck }
  );

  const [makeFeeEstimate, { data: feeEst, isLoading: isFetchingEstimate }] = useWithdrawalFeeEstimateMutation();

  console.log("Fee Estimate", feeEst);

  const { data: resolvedCryptoAddress, isFetching: isResolvingCryptoAddress } = useResolveCryptoDomainQuery(
    { domainName: state?.address!, network: state.network, coin },
    { skip: skipAddressCheck || state.destinationFormat !== "cryptoDomain" }
  );
  const { data: minimumWithdrawData, isLoading: isLoadingMinimum } = useGetMinimumWithdrawalAmountQuery({
    coin,
    network: state?.network,
  });

  const isInternalWithdrawal = useMemo(
    () => checkAddressData?.isInternalWallet || ["username", "email"].includes(String(state.destinationFormat)),
    [checkAddressData, state.destinationFormat]
  );
  const isCryptoDomain = useMemo(() => state.destinationFormat === "cryptoDomain", [state.destinationFormat]);
  const isValidCryptoDomain = useMemo(
    () => !isCryptoDomain || !!resolvedCryptoAddress?.address,
    [isCryptoDomain, resolvedCryptoAddress?.address]
  );
  const internalWalletUsername = useMemo(() => checkAddressData?.username, [checkAddressData]);

  const fee = useMemo(() => {
    if (isInternalWithdrawal) return 0;
    return feeEst?.fee ?? 1;
  }, [feeEst, isInternalWithdrawal]);

  const minimumWithdraw = useMemo(() => {
    let min = minimumWithdrawData?.min ?? 0;
    if (isInternalWithdrawal) return +Number(min / 10).toFixed(2);
    return min;
  }, [minimumWithdrawData, isInternalWithdrawal]);

  const isLessThanMinimum = useMemo(() => !!state?.amount && +state.amount < +(minimumWithdraw ?? 0), [state, minimumWithdraw]);

  const isInsufficientBalance = useMemo(() => !!state?.amount && +state?.amount > balance, [state, balance]);

  const isDisabled = useMemo(
    () =>
      (state?.destinationFormat === "cryptoDomain" && (isResolvingCryptoAddress || !isValidCryptoDomain)) ||
      !(state?.amount && state?.address) ||
      (!!coin && coin === "xrp" && !state?.tag && !isAddressLabel) ||
      (!!coin && coin === "xlm" && !state?.memo && !isAddressLabel) ||
      isLessThanMinimum ||
      isInsufficientBalance,
    [coin, state, isResolvingCryptoAddress, isValidCryptoDomain, isAddressLabel, isLessThanMinimum, isInsufficientBalance]
  );

  const addressLabel = useMemo(() => {
    return when(
      ["username", "email"].includes(String(state.destinationFormat)),
      `${properCase(state.destinationFormat)}`,
      when(["cryptoDomain"].includes(String(state.destinationFormat)), `Domain Name`, `${toCoinLocale(coin)} Address`)
    );
  }, [state, coin]);

  const requestFeeEstimate = async (coin: string, dto: Partial<IState>) => {
    return await makeFeeEstimate({
      coin: coin,
      amount: +(dto?.amount ?? state?.amount ?? 0),
      destinationAddress: (dto?.address ?? state?.address)!,
      tagNumber: dto?.tag ?? state?.tag,
      memo: dto?.memo ?? state?.memo,
      network: (dto?.network ?? state?.network)!,
    }).unwrap();
  };

  const set = async (updates: Partial<IState>) => {
    _set({ ...updates });
    if (!skipAddressCheck && !!updates?.amount) {
      // do a fee estimate here.
      // const est = await requestFeeEstimate(coin, { ...updates });
      redebounce(() => requestFeeEstimate(coin, { ...updates }), "request-fee-estimate", 500)();
      // console.log("Fee Estimate", est);
    }
  };

  const available_networks = useMemo(() => {
    const default_options = [
      { value: "tron", label: "Tron (TRC20)" },
      { value: "ethereum", label: "Ethereum (ERC20)" },
      { value: "polygon", label: "Polygon (MATIC)" },
      // { value: "polygon", label: "Polygon (MATIC)" },
      { value: "celo", label: "Celo" },
    ];

    const map: Record<string, { value: string; label: string }[]> = {
      // [coin]: default_options,
      usdt: [...default_options, { value: "ton", label: "TON" }],
      usdc: [...default_options, { value: "stellar", label: "Stellar" }, { value: "base", label: "Base Coin" }],
      // ton: [{ value: "ton", label: "Ton" }],
    };

    const has_preferred_network = Object.hasOwn(map, coin);
    const networks = when(!!has_preferred_network, map[coin], []);
    return { networks, has_preferred_network };
  }, [coin]);

  const { isOpen: _isOpen, close, open } = useDisclosures<"copy_warning">();
  const [is_consented, setConsented] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!is_consented && state?.network === "ton") {
      open("copy_warning")();
    } else {
      onOpen();
    }
  };

  return (
    <Group as="form" px="1" onSubmit={handleSubmit}>
      {/* {["usdc", "usdt"].includes(coin) && (
        <FormControl mb="32px !important">
          <InputLabel isLoading={isLoadingMinimum}>Network</InputLabel>
          <Select
            placeholder="Select network"
            value={state?.network ?? ""}
            onChange={(e) => set({ network: e.target.value as any })}
          >
            <option value="tron">Tron (TRC20)</option>
            <option value="ethereum">Ethereum (ERC20)</option>
            <option value="polygon">Polygon (MATIC)</option>
            <option value="celo">Celo</option>
            {coin === "usdc" && (
              <>
                <option value="stellar">Stellar</option>
                <option value="base">Base Chain</option>
              </>
            )}
          </Select>

        </FormControl>
      )} */}

      {available_networks.has_preferred_network && (
        <FormControl mb="32px !important">
          <InputLabel isLoading={isLoadingMinimum}>Network</InputLabel>
          <Select
            placeholder="Select network"
            value={state?.network ?? ""}
            onChange={(e) => set({ network: e.target.value as any })}
          >
            {available_networks.networks.map((network) => (
              <option key={network.value} value={network.value}>
                {network.label}
              </option>
            ))}
            {/* <option value="tron">Tron (TRC20)</option>
            <option value="ethereum">Ethereum (ERC20)</option>
            <option value="polygon">Polygon (MATIC)</option>
            <option value="celo">Celo</option>
            {coin === "usdc" && (
              <>
                <option value="stellar">Stellar</option>
                <option value="base">Base Chain</option>
              </>
            )} */}
            {/* {coin === "usdt" && (
              <>
                <option value="algorand">Algorand</option>
              </>
            )} */}
          </Select>

          {/* {<FormInfo mt="12px" info="Fee Estimate: " description={toPrecision(fee, coin, true, 5, true)} />} */}
        </FormControl>
      )}

      <FormControl mb="32px !important">
        <InputLabel>Address Format</InputLabel>
        <Select
          // placeholder="Select address format"
          value={state?.destinationFormat ?? ""}
          onChange={(e) => set({ destinationFormat: e.target.value as any })}
        >
          <option value="address">Crypto Address</option>
          <option value="cryptoDomain">Crypto Domain</option>
          <option value="username">Username</option>
          <option value="email">Email</option>
        </Select>
      </FormControl>

      <FormControl mb="32px !important">
        <InputLabel isLoading={when(isCryptoDomain, isResolvingCryptoAddress, isFetching)}>{addressLabel}</InputLabel>
        <Input
          placeholder={`Enter ${addressLabel}`}
          value={state?.address ?? ""}
          onChange={(e) => {
            setInputState({ address: "typing" });
            redebounce(() => setInputState({ address: "idle" }), "address-input", 1500)();
            set({ address: e.target.value });
          }}
        />

        {isInternalWithdrawal && !!state?.address && state.destinationFormat === "address" && (
          <HStack mt="12px">
            <Avatar size="sm" name={internalWalletUsername} />
            <Text fontSize="14px" fontWeight="500">
              @{internalWalletUsername}
            </Text>
          </HStack>
        )}
        {isCryptoDomain &&
          state.address &&
          inputState.address === "idle" &&
          !isResolvingCryptoAddress &&
          (resolvedCryptoAddress?.address ? (
            <HStack mt="12px">
              <Text fontSize="14px" fontWeight="500">
                {resolvedCryptoAddress?.address || ""}
              </Text>
            </HStack>
          ) : (
            <FormInfo
              info=""
              mt="12px"
              isInvalid={true}
              description={when(
                typeof resolvedCryptoAddress?.error === "string",
                resolvedCryptoAddress?.error,
                "Could not resolve address"
              )}
            />
          ))}
      </FormControl>

      {(coin === "xlm" || (!!state?.network && state.network === "stellar")) && !isAddressLabel && (
        <FormControl mb="32px !important">
          <InputLabel>Memo</InputLabel>
          <Input placeholder="Enter Memo" value={state?.memo ?? ""} onChange={(e) => set({ memo: e.target.value })} />
        </FormControl>
      )}

      {coin === "ton" && !isAddressLabel && (
        <FormControl mb="32px !important">
          <InputLabel>Memo</InputLabel>
          <Input placeholder="Enter Memo" value={state?.memo ?? ""} onChange={(e) => set({ memo: e.target.value })} />
        </FormControl>
      )}

      {["usdc", "usdt"].includes(coin) && state?.network === "ton" && !isAddressLabel && (
        <FormControl mb="32px !important">
          <InputLabel>Memo</InputLabel>
          <Input placeholder="Enter Memo" value={state?.memo ?? ""} onChange={(e) => set({ memo: e.target.value })} />
        </FormControl>
      )}

      {coin === "xrp" && !isAddressLabel && (
        <FormControl mb="32px !important">
          <InputLabel>Tag</InputLabel>
          <Input placeholder="Enter Tag Number" value={state?.tag ?? ""} onChange={(e) => set({ tag: e.target.value })} />
        </FormControl>
      )}

      {["usdc", "usdt"].includes(coin) && state?.network === "algorand" && !isAddressLabel && (
        <FormControl mb="32px !important">
          <InputLabel>Note</InputLabel>
          <Input placeholder="Enter Note" value={state?.note ?? ""} onChange={(e) => set({ note: e.target.value })} />
        </FormControl>
      )}

      <FormControl mb={when(["xlm", "xrp"].includes(coin), "32px !important", "12px !important")}>
        <InputLabel isLoading={isFetchingEstimate}>{toCoinLocale(coin)} Amount</InputLabel>
        <AmountInput
          placeholder="0.00"
          value={state?.amount ?? ""}
          onMax={() => set({ amount: String(Number(balance)) })}
          onChange={(e) => set({ amount: e.target.value })}
        />

        {isLessThanMinimum && (
          <FormInfo mt="12px" isInvalid={isLessThanMinimum} info="" description="Amount is less than minimum withdrawal amount" />
        )}
        {isInsufficientBalance && (
          <FormInfo info="" mt="12px" isInvalid={isInsufficientBalance} description="Insufficient balance" />
        )}
      </FormControl>

      <FormControl>
        <FormInfo isInvalid={isInsufficientBalance} info="Available Balance: " description={toPrecision(balance, coin)} />
        <FormInfo
          isInvalid={isLessThanMinimum}
          info={`Minimum ${toCoinLocale(coin)} Withdrawal: `}
          description={toPrecision(minimumWithdraw, coin)}
        />
        {!!state?.amount && !!state?.address && (
          <>
            {!isFetchingEstimate && <FormInfo info="Fee Estimate: " description={toPrecision(fee, coin, true, 5, true)} />}
            {isFetchingEstimate && <FormInfo info="Fee Estimate: " description={`-- ${toCoinLocale(coin)}`} />}
          </>
        )}
      </FormControl>

      <VStack mt="62px !important">
        <Button minW={{ base: "100%", md: "400px" }} type="submit" disabled={isDisabled}>
          Continue
        </Button>
      </VStack>

      <CopyCryptoAddressWarningModal
        isOpen={_isOpen("copy_warning")}
        onClose={() => {
          close("copy_warning")();
          setConsented(true);
        }}
      />

      <CryptoWithdrawPreview
        isOpen={isOpen}
        onClose={onClose}
        state={state}
        fee={fee}
        coin={coin}
        reset={reset}
        set={set}
        onSuccessClose={() => setCurrentView(0)}
        isInternal={isInternalWithdrawal}
        internalWalletUsername={internalWalletUsername}
      />
    </Group>
  );
}

function History(props: WithdrawProps & { coin: string }) {
  const { coin } = props;

  const color = useColor();
  const { onNext } = useModalView();
  const { updateSidePageConfigs } = useSidePage();

  const [queries, set] = usePartialState({
    page: 1,
    limit: 6,
    unit: coin,
    sortBy: "createdAt:desc",
    coin,
  });

  const { data: tx, isLoading: isTxLoading, isFetching } = useGetWithdrawalsQuery(toQueryString(queries));
  console.log("Withdrawals", tx);

  // const { data: a,  } = useGetWalletTransationsV2Query(toQueryString(queries));

  const totalCount = useMemo(() => tx?.totalCount, [tx]);
  const transactions = useMemo(() => tx?.withdrawals, [tx]);
  const hasTx = useMemo(() => (transactions ?? []).length > 0, [transactions]);
  const passedLimit = useMemo(() => (totalCount ?? 0) > queries.limit!, [totalCount, queries.limit]);

  const desc = useMemo(
    () => (status: string) => {
      const map: Record<string, string> = {
        pending: "Pending withdrawal request",
        approved: "Withdrawal request approved",
        cancelled: "Withdrawal request Cancelled",
        denied: "Withdrawal request Cancelled",
        [status]: `Withdrawal request ${status}`,
      };

      return map[status];
    },
    []
  );

  const handlePageChange = (page: number) => {
    set({ page });
  };

  const handleNextView = (details: WalletWithdrawalRo) => {
    onNext();
    updateSidePageConfigs({
      data: { details },
    });
  };

  return (
    <Group mt="12px !important">
      <Card
        bg={color("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)")}
        w="100%"
        p="8px"
        overflow="hidden"
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tx-list-${when(isTxLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isTxLoading && <PageLoading isLoading={isTxLoading} />}
            {!isTxLoading && (
              <Stack>
                {(transactions ?? []).map((tx, id: any) => (
                  <TransactionItem
                    mt="0 !important"
                    w="100%"
                    key={`transaction-${id}`}
                    unit={tx?.unit}
                    type={tx?.status}
                    value={{ $numberDecimal: tx?.amount }}
                    createdAt={tx?.createdAt}
                    description={desc(tx?.status)}
                    use_type={false}
                    onClick={() => handleNextView(tx)}
                  />
                ))}
              </Stack>
            )}

            {!isTxLoading && !isFetching && !hasTx && <EmptyCrate type="transaction" />}
          </motion.div>
        </AnimatePresence>
      </Card>

      {passedLimit && (
        <Box w="100%">
          <Paginator limit={queries.limit!} onPageChange={handlePageChange} page={queries.page!} totalCount={totalCount ?? 0} />
        </Box>
      )}
    </Group>
  );
}

function WithdrawalIndexView(props: WithdrawProps & { setBackButtonConfig: () => void }) {
  const { params } = useSidePage();
  const coin = useMemo(() => params?.coin ?? "btc", [params]);
  const [tabIndex, changeTab] = useState(0);

  useEffect(() => {
    if (!!props?.setBackButtonConfig) props.setBackButtonConfig();
    //eslint-disable-next-line
  }, []);

  return (
    <Group mt="0 !important" id="withdrawalView__step-1">
      <TitleBar>
        <Title fontSize="20px">{toCoinLocale(coin)} Wallet</Title>
      </TitleBar>

      <Tabs variant="ghost" index={tabIndex} onChange={changeTab} mt="12px !important">
        <TabList px="2" borderRadius="8px">
          <Tab fontSize="md">Withdraw</Tab>
          <Tab fontSize="md">History</Tab>
        </TabList>
        <TabPanels index={tabIndex} onChangeIndex={changeTab}>
          <TabPanel px={["2", "0", "0", ""]}>
            <Form {...props} coin={coin} />
          </TabPanel>
          <TabPanel px={["0", "0", "0", "1"]}>
            <History {...props} coin={coin} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Group>
  );
}

function WithdrawalDetailsView(props: WithdrawProps) {
  const { data, updateSidePageConfigs } = useSidePage();
  const { onPrev, hasPrev } = useModalView();

  const toast = useToast();
  const color = useColor();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const details = useMemo(() => data?.details as WalletWithdrawalRo, [data]);

  const [cancelRequest, { isLoading }] = useCancelWithdrawalRequestMutation();

  console.log("Withdrawal Details", details);

  const handleCancelRequest = async () => {
    if (!details || (!!details && !details?._id)) return;

    const result = await cancelRequest(details?._id).unwrap();
    if (!!result) {
      toast({
        position: "bottom-right",
        duration: 5000,
        title: "Success",
        description: `Withdrawal request successfully cancelled`,
        status: "success",
        isClosable: true,
      });

      !!hasPrev && onPrev();
    }
  };

  useEffect(() => {
    if (!!hasPrev) {
      updateSidePageConfigs({
        _back: {
          text: "Withdraw",
          onClick: onPrev,
        },
      });
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Box id="crypto-withdrawal-detail-view" {...props} overflowY="scroll">
      <TitleBar>
        <Title fontSize="20px">Withdrawal Details</Title>
      </TitleBar>

      <Group>
        <Card
          bg={color("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)")}
          w="100%"
          p="10px 22px"
          borderRadius="12px"
          overflow="hidden"
        >
          <DetailItem title="Transaction Status" description={details.status} _description={{ textTransform: "capitalize" }} />
          <DetailItem title="Date and Time" description={format(parseISO(details?.createdAt), "dd MMM yyyy, hh:mm aa")} />
          <DetailItem title="Withdraw From" description={`${toCoinLocale(details?.unit ?? "btc")} Wallet`} />
          <DetailItem title="Amount" description={toPrecision(+(details?.amount ?? 0), details?.unit ?? "btc")} />
          <DetailItem
            title="To Address"
            description={details?.destination?.destinationAddress ?? "not specified"}
            _description={{ style: { lineBreak: "anywhere" } }}
          />
          {details?.coin === "xlm" && (
            <DetailItem title="Memo" description={details?.destination?.destinationMemo ?? "not specified"} />
          )}
          {!!details?.destination?.destinationNetwork && (
            <DetailItem
              title="Network"
              description={details?.destination?.destinationNetwork ?? "not specified"}
              _description={{ textTransform: "capitalize" }}
            />
          )}
          {details?.coin === "xrp" && (
            <DetailItem title="Tag" description={details?.destination?.destinationTagNumber ?? "not specified"} />
          )}
          {!!details?.destination?.destinationNote && (
            <DetailItem title="Note" description={details?.destination?.destinationNote ?? "not specified"} />
          )}
          <DetailItem
            title="Transaction Ref"
            description={details?.txRef ?? "not specified"}
            isLast={!details?.txLink}
            isCopyable
          />
          {details?.txLink && (
            <DetailItem
              title="Transaction Hash"
              description={details?.txLink}
              isLast={true}
              _description={{
                textDecoration: "underline",
                cursor: "pointer",
                onClick: () => window.open(details?.txLink, "_blank"),
                style: { lineBreak: "anywhere" },
              }}
            />
          )}
        </Card>

        <ConditionalRender shouldRender={["pending"].includes(details?.status ?? "none")}>
          <VStack py="26px">
            <Button
              variant="outline"
              borderColor="error"
              isLoading={isLoading}
              disabled={isLoading}
              isDisabled={isLoading}
              color="error"
              onClick={onOpen}
            >
              Cancel Request
            </Button>
          </VStack>
        </ConditionalRender>

        <ConfirmationModal isOpen={isOpen} onClose={onClose} onConfirm={handleCancelRequest}>
          <VStack my="40px">
            <Text fontWeight="500">Are you sure you want to cancel this request?</Text>
          </VStack>
        </ConfirmationModal>
      </Group>
    </Box>
  );
}

interface BreadcrumbLinkProps extends BCLProps {}

export function BreadcrumbLink(props: BreadcrumbLinkProps) {
  const { children, ...xprops } = props;

  const color = useColorModeValue("secondary.400", "secondary.400");
  return (
    <BCL fontSize="14px" color={color} {...xprops}>
      {children}
    </BCL>
  );
}
