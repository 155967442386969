import { useCallback, useEffect, useRef, useState } from "react";
import { convertNumberMS } from "utils";
import ls from "utils/secureStorage";
import usePartialState from "./usePartialState";

type SavedTimeProgressType = { secondsLeft: number; date: number };

interface IOptions {
  onSave?: (secondsLeft: number) => void;
  onTick?: (tick: number) => void;
  onRestore?: (secondsLeft: number) => void;
  onStart?: () => void;
  onTimedout?: () => void;
}

export function usePersistentCountdown(key?: string, secs = 0, options?: IOptions) {
  const { onSave, onTick, onRestore, onStart, onTimedout } = options || {};
  const restored_progress = useRef(false);
  const [started, setStarted] = useState(false);
  const [{ secondsLeft = 0 }, set] = usePartialState({ secondsLeft: secs }, [secs]);

  // const otp = useOTPStore((store) => store.otp);
  // const setOTP = useOTPStore((store) => store.setOTP);

  const setSecondsLeft = useCallback(
    (value: number) => {
      set({ secondsLeft: value });
      onTick?.(value);
    },
    [set, onTick]
  );

  useEffect(() => {
    // if (secondsLeft <= 0) {
    //   const saved_progress = !!key ? (ls.get(key) as SavedTimeProgressType) : { secondsLeft: 0, date: 0 };
    //   if (saved_progress.secondsLeft < 1 && key) ls.remove(key);
    //   setStarted(false);
    //   return;
    // }

    if (secondsLeft <= 0) {
      onTimedout?.();
      return;
    }

    const timeout = setInterval(() => {
      setSecondsLeft(secondsLeft - 1);
    }, 1000);

    return () => {
      clearInterval(timeout);
      if (key && secondsLeft > 0) {
        ls.set(key, { secondsLeft, date: Date.now() } as SavedTimeProgressType);
        onSave?.(secondsLeft);
      }
      restored_progress.current = false;
    };
  }, [secondsLeft, key, setSecondsLeft, onSave, onTimedout]);

  const startCountdown = useCallback(
    (seconds: number) => {
      if (seconds < 1) return;
      setSecondsLeft(seconds);
      if (!started) {
        setStarted(true);
        onStart?.();
      }
    },
    [setSecondsLeft, started, onStart]
  );

  useEffect(() => {
    const saved_progress = !!key ? (ls.get(key) as SavedTimeProgressType) : { secondsLeft: 0, date: 0 };
    if ((!!saved_progress && saved_progress?.secondsLeft < 1) || (!!saved_progress && !!restored_progress.current)) return;

    console.log("Saved countdown progress", saved_progress);

    const time_diff = (Date.now() - (saved_progress?.date ?? 0)) / 1000;
    const remaining_secs = Math.max(0, (saved_progress?.secondsLeft ?? 0) - time_diff);
    startCountdown(remaining_secs);
    onRestore?.(remaining_secs);
    restored_progress.current = true;
  }, [key, setSecondsLeft, startCountdown, onRestore]);

  const toTimeString = () => {
    return convertNumberMS(String(secondsLeft));
  };

  return { startCountdown, toTimeString, timedout: secondsLeft <= 0, started };
}
