import { RequestPhoneCodeDto } from "interfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { requestPhoneCode } from "store/slices";
import useDispatch from "./useDispatch";
import { useAppConfig } from "contexts/appconfig.context";
import { usePersistentCountdown } from "./usePersistentCountdown";
import { intersection } from "lodash";

type DeliveryType = "sms" | "call" | "whatsapp";

export function useRequestVerificationCode(params: { defaultChannel?: DeliveryType; timerKey?: string }) {
  const { defaultChannel, timerKey } = params;
  const [channel, setChannel] = useState(defaultChannel);

  const dispatch = useDispatch();
  const { appfigs = {} } = useAppConfig();
  const { generalConfig } = appfigs;

  const countdown = usePersistentCountdown(timerKey);

  const requestCode = useCallback(
    async (dto: RequestPhoneCodeDto) => {
      const delivery = dto?.delivery || channel;
      const otp_conf = generalConfig?.otpChannels;
      if (otp_conf?.[delivery]) {
        return await dispatch(requestPhoneCode({ ...dto, delivery }));
      }
      return;
    },
    [dispatch, channel, generalConfig]
  );

  const switchChannel = useCallback(
    (channel: string) => {
      type cType = keyof NonNullable<typeof generalConfig>["otpChannels"];
      const otp_conf = generalConfig?.otpChannels;
      if (otp_conf?.[channel as cType]) {
        setChannel(channel as cType);
      }
    },
    [generalConfig, setChannel]
  );

  const availableChannels = useMemo(() => {
    const otp_conf = generalConfig?.otpChannels as Record<string, boolean>;
    return otp_conf ? Object.keys(otp_conf).filter((c) => !!otp_conf[c]) : [];
  }, [generalConfig]);

  const isChannelAvailable = useCallback(
    (channels: DeliveryType[]) => {
      if (availableChannels?.length < 1) return false;
      const union = intersection(availableChannels, channels);
      return union.length > 0;
    },
    [availableChannels]
  );

  useEffect(() => {
    if (defaultChannel && !isChannelAvailable([defaultChannel])) {
      if (availableChannels.length > 0 && !!availableChannels[0]) {
        setChannel(availableChannels[0] as DeliveryType);
      } else {
        setChannel(undefined);
      }
    }
  }, [defaultChannel, availableChannels, isChannelAvailable]);

  return { requestCode, switchChannel, channel, availableChannels, isChannelAvailable, ...countdown };
}
