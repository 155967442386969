import EventEmitter from "eventemitter3";
import { AppAnalyticEvent } from "interfaces";

export interface IAppAnalyticEventTypes {
  "Buy Initiated": any; // ✅
  "Buy Submitted": any; // ✅
  "Sell Initiated": any; // ✅
  "Sell Submitted": any; // ✅
  "Swap Initiated": any; // ✅
  "Swap Submitted": any; // ✅
  "Withdrawal Initiated": any; // ✅
  "Withdrawal Submitted": any; // ✅
  "Funding Submitted": any; // ✅
  "Funding Initiated": any; // ✅

  "Add Card Label": any; // ✅
  "Create Card Initiated": any; // ✅
  "Create Card Submitted": any; // ✅
  "Card Funding Submitted": any; // ✅
  "Card Funding Initiated": any; // ✅
  "Card Withdrawal Submitted": any; // ✅
  "Card Withdrawal Initiated": any; // ✅
  "Request Card Statement": any;

  // #########

  "Fund Greenbox Initiated": any; // ✅
  "Fund Greenbox Submitted": any; // ✅
  "Create Greenbox Initiated": any; // ✅
  "Create Greenbox Submitted": any; // ✅
  "Withdrawal Greenbox Submitted": any; // ✅
  "Withdrawal Greenbox Initiated": any; // ✅
  "Claimed Greenbox Initiated": any; // ✅
  "Claimed Greenbox Submitted": any; // ✅

  // #########

  "Buy Airtime Initiated": any; // ✅
  "Buy Airtime Submitted": any; // ✅
  "Buy Data Submitted": any; // ✅
  "Buy Data Initiated": any; // ✅

  // #########

  "Earn Clicked": any;
  "Staking Clicked": any;
  "Fixed Staking Clicked": any;
  "Flexible Staking Clicked": any;
  "Earn Staking Initiated": any; // ✅
  "Earn Staking Submitted": any; // ✅
  "Earn Staking Rolled-over": any;
  "Earn Staking Redeemed": any;
  "Transfer Funds Staking Initiated": any; // ✅
  "Transfer Funds Staking Submitted": any; // ✅

  "P2P Buy Initiated": any;
  "P2P Buy Submitted": any;
  "P2P Sell Initiated": any;
  "P2P Sell Submitted": any;
  "P2P Ad Creation Initiated": any;
  "P2P Ad Creation Submitted": any;
  "P2P Buy Ad Created": any;
}

export interface EventTypes extends IAppAnalyticEventTypes {
  PARENT_BUTTON_CLICKED: any;
  NOTIFICATION_CHECK_ALL: any;

  logout: any;
  delete_account: any;
  refresh_token: any;
  new_notification: any;
  read_notification: any;
  remove_notification: any;
  new_greenbox_claim: any;
  new_greenbox_preclaim: any;
  greenbox_update: any;
  announce: any;
  refetch_wallet: any;
  refetch_p2p: any;
  refetch_account_profile: any;
  refetch_staking: any;
  refetch_greenbox: any;
  refetch_cards: any;
  refetch_cards_tx: any;
  refetch_buy_tx: any;
  refetch_sell_tx: any;
  refetch_swap_tx: any;
  refetch_withdrawals: any;
  kyc_approved: any;
  kyc_rejected: any;
  refetch_kyc: any;
  refetch_config: any;

  refetch_deposit: any;
  new_deposit: any;
  new_withdrawal: any;

  command_down: { commands: string[] };
  command_cleared: { commands: string[] };

  refetch_p2p_trade_session: any;

  refetch_badge_earned: any;

  otp_start: { secs: number };
}

const eventEmitter = new EventEmitter<EventTypes>();

type EventNameType = keyof EventTypes | AppAnalyticEvent;
export type FnArgType<T> = EventEmitter.ArgumentMap<EventTypes>[Extract<T, EventNameType>];
export type FnType<T> = (...args: FnArgType<T>) => void;

export const Emitter = {
  on: <T extends EventNameType>(event: T, fn: FnType<T>, context?: any) => eventEmitter.on(event, fn, context),
  once: <T extends EventNameType>(event: T, fn: FnType<T>, context?: any) => eventEmitter.once(event, fn, context),
  off: <T extends EventNameType>(event: T, fn: FnType<T>, context?: any, once?: boolean) =>
    eventEmitter.off(event, fn, context, once),
  emit: <T extends EventNameType>(event: T, ...args: FnArgType<T>) => eventEmitter.emit(event, ...args),
};

Object.freeze(Emitter);

export default Emitter;
