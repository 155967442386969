// import { useStore } from "store";
import { useToast } from "@chakra-ui/react";
import configs, { isCProd } from "config";
import { useAppConfig } from "contexts/appconfig.context";
import { useAuth } from "hooks/useAuth";
import { Emitter, EventTypes, FnType } from "libs";
import Pusher from "pusher-js";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { selectUser } from "store/slices";
import { useOnScreen } from "./useOnScreen";
import useSelector from "./useSelector";

export const toastStatuses: Record<string, any> = {
  info: "info",
  error: "error",
  success: "success",
  warning: "Warning",
};

interface IShowNotificationData {
  title: string;
  status: string;
  message: string;
}

export enum DispatchCommand {
  LOGOUT = "logout",
  DELETE_ACCOUNT = "delete_account",
  REFRESH_TOKEN = "refresh_token",
  NEW_NOTIFICATION = "new_notification",
  REFETCH_WALLET = "refetch_wallet",
  REFETCH_DEPOSIT = "refetch_deposit",
  NEW_DEPOSIT = "new_deposit",
  NEW_WITHDRAWAL = "new_withdrawal",
  READ_NOTIFICATION = "read_notification",
  REMOVE_NOTIFICATION = "remove_notification",
  NEW_GREENBOX_CLAIM = "new_greenbox_claim",
  NEW_GREENBOX_PRECLAIM = "new_greenbox_preclaim",
  GREENBOX_UPDATE = "greenbox_update",
  ANNOUNCE = "announce",
  REFETCH_P2P = "refetch_p2p",
  REFETCH_ACCOUNT_PROFILE = "refetch_account_profile",
  REFETCH_STAKING = "refetch_staking",
  REFETCH_GREENBOX = "refetch_greenbox",
  REFETCH_CARDS = "refetch_cards",
  REFETCH_CARDS_TX = "refetch_cards_tx",
  REFETCH_BUY_TX = "refetch_buy_tx",
  REFETCH_SELL_TX = "refetch_sell_tx",
  REFETCH_SWAP_TX = "refetch_swap_tx",
  REFETCH_WITHDRAWALS = "refetch_withdrawals",
  KYC_APPROVED = "kyc_approved",
  KYC_REJECTED = "kyc_rejected",
  REFETCH_KYC = "refetch_kyc",
  REFETCH_CONFIG = "refetch_config",
  REFETCH_BADGE_EARNED = "refetch_badge_earned",
  GAMIFICATION_NOTIFICATION = "gamification_notification",
  REFETCH_FEATURE_CONFIG = "feature_config_refetch",
}

interface DispatchPayload {
  isCommand?: boolean;
  command?: DispatchCommand;
  createdAt: Date;
  dispatchId: string;
  metaData?: Record<string, any> & { message: string };
}

export function useInlineWebPush(skip: boolean) {
  const { auth, logout } = useAuth();
  const onScreen = useOnScreen();
  const pusherStream = useRef<Record<string, any>>({ initedPusher: false });
  const toast = useToast();

  const { refetch: refetchAppConfig, refetchLocaleFigs } = useAppConfig();

  const { profile } = useSelector(selectUser);
  const userData = useMemo(() => profile, [profile]);

  const webNotify = useCallback(
    (data: any) => {
      if (window.Notification && Notification.permission === "granted") {
        var i = 0;
        // Using an interval cause some browsers (including Firefox) are blocking notifications if there are too much in a certain time.
        var interval = window.setInterval(function () {
          new Notification(data.title, { tag: data?.tag || "notification" });
          if (i++ === 9) {
            window.clearInterval(interval);
          }
        }, 200);
      }

      // If the user hasn't told if they want to be notified or not
      // Note: because of Chrome, we are not sure the permission property
      // is set, therefore it's unsafe to check for the "default" value.
      else if (window.Notification && Notification.permission !== "denied") {
        Notification.requestPermission(function (status) {
          // If the user said okay
          if (status === "granted") {
            var i = 0;
            var interval = window.setInterval(function () {
              new Notification(data.title, { tag: data?.tag || "notification" });
              if (i++ === 9) {
                window.clearInterval(interval);
              }
            }, 200);
          }

          // Otherwise, we can fallback to a regular modal alert
          else {
            toast(data);
          }
        });
      }
      // If the user refuses to get notified
      else {
        // We can fallback to a regular modal alert
        toast(data);
      }
    },
    [toast]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showNotif = (data: IShowNotificationData) => {
    const toastIt = 2 + 2 === 4;
    if (toastIt) {
      toast({
        position: "top",
        title: data?.title || "New Update",
        description: data?.message || "",
        status: toastStatuses[data.status] || "info",
        variant: "top-accent",
        duration: 9000,
        isClosable: true,
      });
    } else {
      // because this dude is unstable
      webNotify({
        position: "top",
        title: data?.title || "New Update",
        description: data?.message || "",
        status: toastStatuses[data.status] || "info",
        variant: "top-accent",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleSystemCommand = useCallback(
    (data: DispatchPayload) => {
      if (data.command) {
        console.log("Pusher Command", data);
        switch (data.command) {
          case DispatchCommand.LOGOUT:
            // logout the user, require re-authentication
            logout();
            break;
          case DispatchCommand.DELETE_ACCOUNT:
            // logout the user, require re-authentication
            logout();
            break;
          case DispatchCommand.REFRESH_TOKEN:
            // display message in data.metaData.message if present
            // and refresh user token, or log user out
            break;
          case DispatchCommand.REFETCH_ACCOUNT_PROFILE:
            // display message in data.metaData.message if present
            // and refetch update profile account
            // Emitter.emit(data.command, {} as any);
            break;
          case DispatchCommand.REFETCH_DEPOSIT:
            // display message in data.metaData.message if present
            // and refetch update profile account
            Emitter.emit("refetch_deposit", data?.metaData ?? {});
            break;
          case DispatchCommand.REFETCH_CONFIG:
            // refetch app config when there's an update
            if (!!refetchAppConfig) refetchAppConfig();
            break;
          case DispatchCommand.REFETCH_FEATURE_CONFIG:
            // refetch app feature config when there's an update
            if (!!refetchLocaleFigs) refetchLocaleFigs();
            break;
          case DispatchCommand.REFETCH_WALLET:
            // refetch app config when there's an update
            Emitter.emit("refetch_wallet", data?.metaData ?? {});
            break;
          case DispatchCommand.REFETCH_BADGE_EARNED:
            // refetch app config when there's an update
            Emitter.emit("refetch_badge_earned", data ?? {});
            break;
          default:
            break;
        }
      }
    },
    [logout, refetchAppConfig, refetchLocaleFigs]
  );

  const pusherSelfHandler = useMemo(
    () => ({
      notification: {
        register: auth?.isSignedIn || onScreen,
        do: showNotif,
      },
      testEvent: {
        register: auth?.isSignedIn || onScreen,
        do: showNotif,
      },
      command: {
        register: auth?.isSignedIn || onScreen,
        do: handleSystemCommand,
      },
      announcement: {
        register: false,
        do: () => {},
      },
      offer: {
        register: false,
        do: () => {},
      },
    }),
    [auth?.isSignedIn, showNotif, onScreen, handleSystemCommand]
  );

  const pusherGlobalHandler = pusherSelfHandler;

  const initPusherAuth = useCallback(
    async (channelName: string, handlers: any, endpoint: string) => {
      if (!pusherStream.current[channelName]) {
        const pusherHost = configs.REACT_APP_PUSHER_HOST || "";

        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
          authEndpoint: `${process.env.REACT_APP_API_ENDPOINT}/${endpoint}`,
          cluster: process.env.REACT_APP_PUSHER_CLUSTER,
          ...(pusherHost && {
            cluster: undefined,
            wsHost: pusherHost,
            enabledTransports: ["ws", "wss"],
          }),
          auth: {
            params: { channelName },
            headers: { authorization: auth?.token },
          },
        });
        const currentSub = pusher.subscribe(channelName);
        pusherStream.current = {
          ...pusherStream.current,
          [channelName]: currentSub,
          [`inited-${channelName}`]: true,
          initedPusher: true,
        };

        const binders = Object.keys(handlers);
        binders.forEach((binding) => {
          if (handlers[binding]?.register) {
            pusherStream?.current[channelName]?.bind(binding, handlers[binding]?.do);
          }
        });
      } else {
      }
    },
    [auth?.token]
  );

  useEffect(() => {
    if (!skip && auth?.isSignedIn && userData && !pusherStream?.current?.initedPusher) {
      pusherStream.current.initedPusher = true;
      const selfChannel = userData?.userId || userData?._id;
      let globalChannel = "all-users";
      if (!isCProd) {
        globalChannel = "sandbox-all-users";
      }
      !pusherStream.current[`inited-${selfChannel}`] && initPusherAuth(selfChannel, pusherSelfHandler, "realtime/self/auth");
      !pusherStream.current[`inited-${globalChannel}`] &&
        initPusherAuth(globalChannel, pusherGlobalHandler, "realtime/global/auth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, auth?.isSignedIn, userData]);

  useEffect(() => {
    return () => {
      if (pusherStream.current.initedPusher) {
        console.debug("Unsubscribed from websocket");
        const bindings = Object.keys(pusherStream.current);

        bindings.forEach((binding) => {
          if (pusherStream.current[binding] === true) {
            pusherStream.current[binding] = false;
          } else {
            pusherStream.current[binding]?.unsubscribe?.(binding);
          }
        });

        pusherStream.current.initedPusher = false;
      }
    };
  }, []);
}

export function makeEventSubscription(event: keyof EventTypes) {
  return (callback: (...args: any[]) => void) => {
    Emitter.on(event, callback);
    return () => {
      Emitter.off(event, callback);
    };
  };
}

export function useEventListener<T extends keyof EventTypes>(event: T, callback: FnType<T>) {
  useEffect(() => {
    Emitter.on(event, callback);
    return () => {
      Emitter.off(event, callback);
    };
  }, [callback, event]);
}
