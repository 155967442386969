import {
  Box,
  Button,
  FormControl,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";
import { Group, Icon, InputLabel, Link, Logo } from "components";
import Recaptcha from "react-google-recaptcha";
import Input from "components/Input/Input";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "hooks";
import { forgotPassword, selectAuth } from "store/slices";
import configs from "config";
import { navigate } from "@reach/router";
import { AuthImage } from "ui";
import { ForgotPasswordSVG, LeftArrowSVG } from "assets";

export default function ForgotPassword(props: { path: string }) {
  const recaptchaRef = useRef<any>(null);
  const dispatch = useDispatch();
  const {
    resetPassword: { status },
  } = useSelector(selectAuth);

  const attempted = useRef(false);
  const [email, setEmail] = useState<string | null>(null);

  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");
  const [isMobile] = useMediaQuery("(max-width: 1023px)");

  const hColor = useColorModeValue("#303131", "white");
  const pColor = useColorModeValue("grey.500", "grey.150");

  const logoColor = useColorModeValue("primary.default", "secondary.500");

  const isLoading = useMemo(() => status === "loading", [status]);

  // const { channel, switchChannel, startCountdown } =
  //   useRequestVerificationCode({
  //     defaultChannel: "sms",
  //     timerKey: "RESET_CODE",
  //   });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const captcha = await recaptchaRef.current?.executeAsync();
    recaptchaRef.current?.reset();

    dispatch(forgotPassword({ email: email as string, humanKey: captcha }));
    attempted.current = true;
  };

  const isDisabled = useMemo(() => !email || isLoading, [email, isLoading]);

  useUpdateEffect(() => {
    if (attempted.current && status === "success") {
      navigate(configs.paths.verifyResetCode.concat(`?email=${email}`), { replace: true, state: { email } });
    }
  }, [status, attempted]);

  return (
    <Box
      minH="100vh"
      pos="relative"
      sx={{
        "--w": isLargerThan1080 ? "580px" : "480px",
      }}
    >
      <AuthImage isMobile={isMobile} />

      <Box minH="100vh" pos="relative" w={isMobile ? "100%" : "calc(100% - var(--w))"} ml="auto">
        <Group maxWidth="523px" margin="auto" padding="32px 20px 43px">
          {isMobile && (
            <Link to="/login" mb="24px">
              <LeftArrowSVG />
            </Link>
          )}

          <Link to="/login">
            <Logo fontSize="54px" _name={{ w: "110px" }} color={logoColor} />
          </Link>

          <Box margin={{ sm: "22px 0 83px !important", "2sm": "66px 0 58px !important" }}>
            <ForgotPasswordSVG />
          </Box>

          <Box mb="40px !important" textAlign={isMobile ? "center" : "left"}>
            <Heading fontSize={{ sm: "24px", "2sm": "32px" }} color={hColor}>
              Forgot your Password?
            </Heading>
            <Text color={pColor} fontSize="18px" mt="14px">
              Enter your registered email to reset password
            </Text>
          </Box>

          <Stack as="form" gridGap="24px" onSubmit={handleSubmit}>
            <FormControl>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                isRequired
                type="email"
                value={email ?? ""}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email"
              />
            </FormControl>

            <VStack mt="36px !important" alignItems="center">
              <Recaptcha ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />
              <Button
                minW="initial"
                maxW="399px"
                width="100%"
                isLoading={isLoading}
                type="submit"
                disabled={isDisabled}
                rightIcon={<Icon type="arrowRight" color="white" />}
              >
                Reset
              </Button>
            </VStack>
          </Stack>
        </Group>
      </Box>
    </Box>
  );
}
