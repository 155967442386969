import { Box, Button, ButtonProps } from "@chakra-ui/react";
import { ConditionalRender } from "components/ConditionalRender/ConditionalRender";
import Icon from "components/Icon/Icon";
import { useColor, useEventListener, useRequestVerificationCode } from "hooks";
import { Fragment, useMemo, useState } from "react";
// import { useOTPStore } from "store/otp.store";
import { when } from "utils";

interface SendOTPProps extends ButtonProps {
  //   email?: string;
  //   requestType: IResendOTP["request_type"];

  /** @param timeStart - The time when the user first requested for OTP. If it's 0,
   * it means the user has not requested for OTP yet.
   * */
  timeStart: number;
  timerKey: string;
  label?: string | ((hasMadeFirstReq: boolean) => string);

  channel?: "sms" | "call" | "whatsapp" | string;
  sendOtp: (startCountdown?: (secs: number) => void) => Promise<void> | void;
  isLoading?: boolean;
  isDisabled?: boolean;

  useRequestCode: ReturnType<typeof useRequestVerificationCode>;
}

export function SendOTP(props: SendOTPProps) {
  const { label = "Send code", timeStart, timerKey, sendOtp, isLoading, isDisabled, useRequestCode, ...xprops } = props;
  const { timedout, started, toTimeString, startCountdown } = useRequestCode || {};

  const color = useColor();
  const [hasMadeFirstReq, setHasMadeFirstReq] = useState(timeStart > 0);

  useEventListener("otp_start", (data) => {
    startCountdown(data?.secs ?? 10);
  });

  const label_str = useMemo(() => {
    if (typeof label === "function") return label(hasMadeFirstReq);
    return label;
  }, [hasMadeFirstReq, label]);

  const isRunning = useMemo(() => !!started && !timedout, [started, timedout]);

  const requestOtp = async () => {
    await sendOtp?.(startCountdown);
    setHasMadeFirstReq(true);
  };

  return (
    <Fragment>
      <ConditionalRender shouldRender={isRunning}>
        <Box fontSize="16px" fontWeight="700" px="6px" py="10px" color={color("primary.default", "secondary.500")}>
          {/* {label_str} ({format(asDate, "mm:ss")}s) */}
          {toTimeString()}
        </Box>
      </ConditionalRender>

      <ConditionalRender shouldRender={!isRunning}>
        <Button
          variant="max"
          minW="fit-content"
          maxW="fit-content"
          p="0 10px"
          fontSize="14px"
          onClick={requestOtp}
          isDisabled={isLoading || isDisabled}
          isLoading={isLoading}
          {...xprops}
        >
          {label_str}
        </Button>
      </ConditionalRender>
    </Fragment>
  );
}

interface ToggleSmsOrCallChannelProps extends ButtonProps {
  channel: string;
  setChannel: (channel: string) => void;
  isSmsOrCallChannelAvailable: boolean;
}

export function ToggleSmsOrCallChannel(props: ToggleSmsOrCallChannelProps) {
  const { channel, setChannel, isSmsOrCallChannelAvailable, ...xprops } = props;

  const color = useColor();

  const mode_color = color("primary.default", "secondary.400");
  const { isChannelAvailable } = useRequestVerificationCode({ defaultChannel: channel as any });

  const toggle = () => {
    if (!isChannelAvailable(["sms", "call"])) return;
    if (channel === "sms" && isChannelAvailable(["call"])) return setChannel("call");
    return isChannelAvailable(["sms"]) && setChannel("sms");
  };

  return (
    <ConditionalRender shouldRender={isSmsOrCallChannelAvailable && isChannelAvailable(["sms"]) && isChannelAvailable(["call"])}>
      <Button
        variant="link"
        minW="fit-content"
        maxW="fit-content"
        p="0 10px"
        fontSize="14px"
        fontWeight="600"
        mt="10px !important"
        textDecoration="underline"
        minH="28px"
        color={mode_color}
        onClick={toggle}
        textTransform="none"
        leftIcon={when(channel !== "call", <Icon type="calling" color={mode_color} />, <></>)}
        rightIcon={
          channel === "sms" || channel === "call" ? <Icon type="checkRounded" color={"secondary.500"} boxSize="16px" /> : <></>
        }
        {...xprops}
      >
        {when(channel !== "call", "Call me via phone instead", "Send code via sms")}
      </Button>
    </ConditionalRender>
  );
}

interface SendViaWhatsappProps extends ButtonProps {
  channel: string;
  setChannel: (channel: string) => void;
  isWhatsappChannelAvailable: boolean;
  sendOtp: (startCountdown?: (secs: number) => void) => Promise<void> | void;
}

export function SendViaWhatsapp(props: SendViaWhatsappProps) {
  const { channel, setChannel, isWhatsappChannelAvailable, sendOtp, ...xprops } = props;

  const color = useColor();

  const mode_color = color("primary.default", "secondary.400");

  const send = async () => {
    setChannel("whatsapp");
    await sendOtp?.();
  };

  return (
    <ConditionalRender shouldRender={isWhatsappChannelAvailable}>
      <Button
        variant="link"
        minW="fit-content"
        maxW="fit-content"
        p="0 10px"
        fontSize="14px"
        fontWeight="600"
        mt="10px !important"
        textDecoration="underline"
        minH="28px"
        color={mode_color}
        onClick={send}
        textTransform="none"
        leftIcon={<Icon type="whatsappOutline" color={mode_color} boxSize="20px" />}
        rightIcon={channel === "whatsapp" ? <Icon type="checkRounded" color={"secondary.500"} boxSize="16px" /> : <></>}
        {...xprops}
      >
        Send via Whatsapp
      </Button>
    </ConditionalRender>
  );
}
